<template>
<div>
	<v-card>
		<v-card-title>
			<template v-if="isUpdate">
				编辑工人信息
			</template>
			<template v-else>
				添加工人信息
			</template>
		</v-card-title>
		<v-divider></v-divider>
		<div class="pa-3">
			<v-form
				ref="form"
				class="pa-3"
				v-model="valid"
				lazy-validation
				@submit.prevent="onFormSubmit"
			>
				<v-text-field
					v-model="worker.name"
					:counter="2"
					outlined
					dense
					:rules="nameRules"
					label="工人姓名"
					:disabled="isUpdate"
					required
				></v-text-field>
				<!--- date information --->
				<v-row>
					<v-col>
						<v-text-field
							:value="computedDateFormattedDatefns(worker.start_date)"
							outlined
							dense
							hide-details
							label="工人开始日期"
							readonly
							required
							@click="startDateMenu = true"
							@click:clear="worker.start_date = null"
						></v-text-field>
						<v-dialog v-model="startDateMenu" max-width="290">
							<v-date-picker
								v-model="worker.start_date"
								:first-day-of-week="0"
								show-current
								locale="zh-cn"
								class="pb-6"
								@change="startDateMenu = false"
							></v-date-picker>
						</v-dialog>
					</v-col>
					<v-col>
						<v-text-field
							:value="computedDateFormattedDatefns(worker.end_date)"
							outlined
							dense
							hide-details
							label="工人结束日期"
							readonly
							@click="endDateMenu = true"
							@click:clear="worker.end_date = null"
						></v-text-field>
						<v-dialog v-model="endDateMenu" max-width="290">
							<v-date-picker
								v-model="worker.end_date"
								:first-day-of-week="0"
								show-current
								locale="zh-cn"
								class="pb-6"
								@change="endDateMenu = false"
							></v-date-picker>
						</v-dialog>
					</v-col>
				</v-row>
				<!--- other information --->

				<v-row class="mb-4">
					<v-col>
						<v-text-field
							v-model="worker.phone"
							outlined
							dense
							hide-details
							label="联系电话"
							required
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							:value="computedDateFormattedDatefns(worker.date_of_birth)"
							outlined
							dense
							hide-details
							label="工人出生日期"
							readonly
							@click="dobDateMenu = true"
							@click:clear="worker.date_of_birth = null"
						></v-text-field>
						<v-dialog v-model="dobDateMenu" max-width="290">
							<v-date-picker
								v-model="worker.date_of_birth"
								:first-day-of-week="0"
								show-current
								locale="zh-cn"
								class="pb-6"
								@change="dobDateMenu = false"
							></v-date-picker>
						</v-dialog>
					</v-col>
				</v-row>
				<v-text-field
					v-model="worker.address"
					outlined
					dense
					label="家庭住址"
					required
				></v-text-field>
				<v-text-field
					v-model="worker.email"
					outlined
					dense
					:rules="emailRules"
					label="电子邮件"
					required
				></v-text-field>
				<v-row class="mt-1">
					<v-col>
						<v-text-field
							v-model="worker.tfn"
							outlined
							dense
							label="TFN"
							hide-details
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="worker.work_safe"
							outlined
							dense
							label="Work Safe"
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="worker.cbus"
							outlined
							dense
							label="CBUS"
							hide-details
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="worker.incolink"
							outlined
							dense
							label="IncoLink"
							hide-details
						></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="worker.coinvest"
							outlined
							dense
							label="COInvest"
							hide-details
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="worker.cfmenu"
							outlined
							dense
							label="CFMenu"
							hide-details
						></v-text-field>
					</v-col>
				</v-row>

				<v-textarea
					label="其它信息"
					dense
					class="mt-6"
					outlined
					v-model="worker.notes"
				></v-textarea>
				<template v-if="isUpdate">
					<div class="subtitle-2 mb-2">选择工人状态:</div>
					<v-btn-toggle
						v-model="worker.archived"
						color="primary"
						class="mb-2"
						mandatory
						style="width:100%"
						@change="onStatusUpdate($event)"
					>
						<v-btn value="0" class="flex-grow-1">
							激活状态
						</v-btn>
						<v-btn value="1"  class="flex-grow-1">
							归档状态
						</v-btn>
					</v-btn-toggle>
				</template>
				<div class="d-flex justify-space-end mt-4 pt- 2">
					<v-btn
						:disabled="!valid"
						color="success"
						elevation="0"
						block
						@click="validate"
						type="submit"
					>
						{{submitText}}
					</v-btn>
				</div>
			</v-form>
		</div>
	</v-card>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
		<!--- loading overlay ---->
		<v-overlay :value="overlay">
			<v-progress-circular
			:size="70"
			:width="7"
			color="primary"
			indeterminate
			></v-progress-circular>
		</v-overlay>
		</div>
</template>

<script>

import WorkerService from '../../services/Worker';
import {  parse, format } from 'date-fns';

export default {
	name: 'userForm',
	data: () => ({
		valid: true,
		nameRules: [
			v => !!v || '请输入工人姓名',
			v => (v && v.length >= 2) || '项目名称至少2个字',
		],
		requiredRules: [
			v => !!v || '必填内容',
		],
		emailRules: [
			v => {
				 const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  		          return pattern.test(v) || '请输入正确的电子邮件格式'
			}
		],
		worker_id: null,
		worker: {
			name: null,
			start_date: null,
			end_date: null,
			date_of_birth: null,
			phone: null,
			address: null,
			email: null,
			tfn: null,
			work_safe: null,
			cbus: null,
			incolink: null,
			coinvest: null,
			cfmenu: null,
			notes: null
		},
		workStartDate_actual: '',
		workEndDate_actual: '',
		workDOB_actual: '',
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		startDateMenu: false,
		endDateMenu: false,
		dobDateMenu: false,
		overlay: false,
		isUpdate: false
	}),
	props: {
		workerData: {
			type: Object,
			required: false,
		},
	},
	watch: {
		workerData(){
			this.workDataUpdate();
		},
		'worker.start_date'(val){
			this.workStartDate_actual = this.formatDate(val);
		},
		'worker.end_date'(val){
			this.workEndDate_actual = this.formatDate(val);
		},
		'worker.date_of_birth'(val){
			this.workDOB_actual = this.formatDate(val);
		}
	},
	computed: {
		submitText(){
			return this.isUpdate ? '更新工人' : '添加工人';
		}
	},
	created(){
		this.workDataUpdate();
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		resetValidation () {
			this.$refs.form.resetValidation()
		},
		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		computedDateFormattedDatefns (date) {
			return date ? this.formatDate(date) : ''
		},
		workDataUpdate(){
			if(this.workerData != null){
				this.worker.name = this.workerData.name;
				if(this.workerData.start_date !== null){
					let formatDate = parse(this.workerData.start_date, 'dd/MM/yyyy', new Date());
					this.worker.start_date= format(formatDate, 'yyyy-MM-dd');
				}
				if(this.workerData.end_date !== null){
					let formatDate = parse(this.workerData.end_date, 'dd/MM/yyyy', new Date());
					this.worker.end_date= format(formatDate, 'yyyy-MM-dd');
				}

				if(this.workerData.date_of_birth !== null){
					let formatDate = parse(this.workerData.date_of_birth, 'dd/MM/yyyy', new Date());
					this.worker.date_of_birth= format(formatDate, 'yyyy-MM-dd');
				}

				this.worker.phone = this.workerData.phone;
				this.worker.address = this.workerData.address;
				this.worker.email = this.workerData.email;
				this.worker.tfn = this.workerData.tfn;
				this.worker.work_safe = this.workerData.work_safe;
				this.worker.cbus = this.workerData.cbus;
				this.worker.incolink = this.workerData.incolink;
				this.worker.coinvest = this.workerData.coinvest;
				this.worker.cfmenu = this.workerData.cfmenu;
				this.worker_id = this.workerData.id;
				this.worker.archived = this.worker.archived;
				this.isUpdate = true;
			}
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"name": this.worker.name,
				"start_date": this.workStartDate_actual && this.workStartDate_actual.length ? this.workStartDate_actual : null,
				"end_date": this.workEndDate_actual && this.workEndDate_actual.length ? this.workEndDate_actual : null,
				"date_of_birth":  this.workDOB_actual && this.workDOB_actual.length ? this.workDOB_actual : null,
				"phone":  this.worker.phone &&this.worker.phone.length ? this.worker.phone : '',
				"address": this.worker.address && this.worker.address.length ? this.worker.address : '',
				"email":  this.worker.email &&this.worker.email.length ? this.worker.email : '',
				"tfn":  this.worker.tfn && this.worker.tfn.length ? this.worker.tfn : '',
				"work_safe":  this.worker.work_safe && this.worker.work_safe.length ? this.worker.work_safe : '',
				"cbus":  this.worker.cbus && this.worker.cbus.length ? this.worker.cbus : '',				
				"incolink":  this.worker.incolink && this.worker.incolink.length ? this.worker.incolink : '',
				"coinvest":  this.worker.coinvest && this.worker.coinvest.length ? this.worker.coinvest : '',
				"cfmenu":  this.worker.cfmenu && this.worker.cfmenu.length ? this.worker.cfmenu : '',
				"notes": this.worker.notes && this.worker.notes.length ? this.worker.notes : '',
				"archived": this.worker.archived > 0 ? true : false
			}

			if(this.isUpdate){
				this.updateWorker(body);
			} else {
				this.createWorker(body);
			}
		},
		async updateWorker(body){
			try {
				let response = await WorkerService.updateWorker(this.worker_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工人${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					setTimeout(()=>{
						this.snackbar.model = false;
						this.$emit('on-submit-success');
					}, 1000)
				}
			} catch(error){
				this.snackbar.message = `错误信息：${error.data.message}`;
				this.snackbar.color = 'error';
				this.overlay = false;
				this.snackbar.model = true;
			}
		},
		async createWorker(body){
			try {
				let response = await WorkerService.careateWorker(body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `工人${response.data.message}`;
					this.snackbar.color = 'success';
					this.overlay = false;
					this.snackbar.model = true;
					let workerId = response.data.data.id;
					this.$emit('on-submit-success', workerId);
					this.reset();
					setTimeout(()=>{
						this.snackbar.model = false;
					}, 1000)
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.overlay = false;
					this.snackbar.model = true;
			}
		},

		onStatusUpdate(event){
			this.worker.archived = event;
		}
	}
}
</script>

<template>
	<v-sheet v-if="team_id != null">
		<v-toolbar flat>
			<v-toolbar-title>
				{{team}}工作记录
			</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn text color="primary" small @click="$router.go(-1)">返回上页</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<template v-if="loading">
			<v-sheet class="d-flex flex-column justify-center align-center" height="60vh">
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">刷新数据中</div>
			</v-sheet>
		</template>
		<template v-else>
			<v-form
			ref="form"
			v-model="valid"
			lazy-validation
			@submit.prevent="updateTimesheetWorkTeam"
			>
				<v-container class="pa-0">
						<div style="padding-bottom:120px">
							<v-subheader class="grey lighten-4">
								<div class="caption font-weight-medium">Day Labour (天工)</div>
								<v-spacer></v-spacer>
								<div class="caption font-weight-heavy">工时: {{dayLabour.total_hours}} 小时</div>
							</v-subheader>
							<v-divider class="mb-3"></v-divider>
							<v-container>
								<div class="d-flex justify-space-between">
									<v-text-field
										v-model="dayLabour.count"
										dense
										outlined
										type="number"
										:rules="numberRules"
										:disabled="!isTimesheetEditable"
										hide-details
										label="天工人数"
										required
									></v-text-field>
									<v-spacer></v-spacer>
									<v-btn
										text
										small
										color="primary"
										@click.prevent="editWorkerRecords(false)"
										>
										<template v-if="isTimesheetEditable">
											<v-icon small left>mdi-pencil</v-icon>编辑工作记录
										</template>
										<template v-else>
											<v-icon small left>mdi-eye</v-icon>查看工作记录
										</template>
									</v-btn>
								</div>
							</v-container>
							<v-divider class="mt-6"></v-divider>
							<v-subheader class="grey lighten-4">
								<div class="caption font-weight-medium">Package Labour (包工)</div>
								<v-spacer></v-spacer>
								<div class="caption font-weight-heavy">工时: {{packageLabour.total_hours}} 小时</div>
							</v-subheader>
							<v-divider class="mb-3"></v-divider>
							<v-container>
								<div class="d-flex justify-space-between">
									<v-text-field
										v-model="packageLabour.count"
										dense
										outlined
										type="number"
										:rules="numberRules"
										:disabled="!isTimesheetEditable"
										hide-details
										label="包工人数"
										required
									></v-text-field>
									<v-spacer></v-spacer>
									<v-btn
										text
										small
										color="primary"
										@click.prevent="editWorkerRecords(true)"
										>
										<template v-if="isTimesheetEditable">
											<v-icon small left>mdi-pencil</v-icon>编辑工作记录
										</template>
										<template v-else>
											<v-icon small left>mdi-eye</v-icon>查看工作记录
										</template>
									</v-btn>
								</div>
							</v-container>
							<v-divider class="mt-6"></v-divider>
							<div class="d-flex pa-3 grey lighten-3">
								<strong class="body-2 font-weight-heavy pr-3">
									总人数:
									{{ parseInt(dayLabour.count) + parseInt(packageLabour.count) }}
								</strong>
								<v-spacer></v-spacer>
								<strong class="body-2 font-weight-heavy pr-3">
									总工时:
									{{ parseFloat(dayLabour.total_hours) + parseFloat(packageLabour.total_hours) }} 小时
								</strong>
							</div>
							<v-divider></v-divider>
						</div>

				</v-container>
				<v-container class="fixedSaveButton" v-if="isTimesheetEditable">
					<v-divider class="mb-3"></v-divider>
					<v-btn	block
							color="success"
							elevation="0"
							@click="validate"
							type="submit"
						>
						保存人员记录</v-btn>
				</v-container>
			</v-form>
		</template>






		<!--- Edit Worker Timesheet --->
		<template v-if="workerLoading">
			<v-overlay>
				<v-progress-circular
					:size="70"
					:width="3"
					color="primary"
					indeterminate
				></v-progress-circular>
				<div class="subtitle-1 mt-4 ">获取工人数据中</div>
			</v-overlay>
		</template>
		<template v-else>
			<v-dialog
				fullscreen
				hide-overlay
				transition="dialog-bottom-transition"
				scrollable
				v-model="dlgEditWorkerRecords">
			<team-worker-records
					:timesheetTeamId.sync="team_id"
					:workTypes.sync="workTypes"
					:isPackage="isPackage"
					:isRecordEditable="isTimesheetEditable"
					@on-update-complete="onUpdateComplete"
				>
					<template v-slot:title>
							<v-btn
							icon
							dark
							@click="dlgEditWorkerRecords = false"
							>
							<v-icon>mdi-close</v-icon>
							</v-btn>
							<v-toolbar-title>工作记录</v-toolbar-title>
					</template>
				</team-worker-records>
			</v-dialog>
		</template>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>

<script>
import TimesheetService from '../../services/Timesheet';
import TeamWorkerList from '../../components/Timesheet/teamWorkerList';
import TeamWorkerRecords from '../../components/Timesheet/teamWorkerRecords';
import ProjectServices from '../../services/Project';
import WorkerForm from '../../components/Works/WokerFrom';

export default {
	name: 'viewTimesheetTeam',
	components: {
		TeamWorkerList,
		TeamWorkerRecords,
		WorkerForm
	},
	created(){
		this.team_id = this.$route.params.id;
		this.isTimesheetEditable = !!this.$route.query.status;
		this.project_id = this.$route.query.pid;
		this.getTimesheetWorkTeam();
	},
	data: () => ({
		team_id: null,
		team_tempalte_id: null,
		project_id: null,
		team: null,
		timesheet_date: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		loading: false,
		workerLoading: false,
		dlgEditWorkerRecords: false,
		isPackage: false,
		workTypes: [],
		isTimesheetEditable: true,

		valid: true,
		numberRules: [
			v => !!v || '必填内容',
			v => (v && !isNaN(v)) || `请输入数字。例如：30`,
		],
		dayLabour: {
			count: 0,
			total_hours: 0
		},
		packageLabour:  {
			count: 0,
			total_hours: 0
		},
	}),
	computed: {
	},
	watch: {
		loading(){
			if(this.loading){
				setTimeout( ()=>{
					this.loading = false;
				}, 1000)
			}
		},
		workerLoading(){
			if(this.workerLoading){
				setTimeout( ()=>{
					this.workerLoading = false;
				}, 500)
			}
		}
	},
	methods: {
		async getTimesheetWorkTeam(){
			try {
				let response = await TimesheetService.getTimesheetWorkTeam(this.team_id);
				if(response.data.statusCode === 200){
					this.team = response.data.data.work_team.name;
					this.teamType = response.data.data.work_team.type;
					this.dayLabour = response.data.data.day_labour;
					this.packageLabour = response.data.data.package;
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}
		},
		
		async getWorkTypeList(){
			try {
				let response = await ProjectServices.listAvailableWorkTypes(this.project_id);
				if(response.status === 200){
						response.data.data.forEach(workType=>{
							this.workTypes.push(workType);
						})
				}
			} catch(error){
				console.log(error)
			}
		},
		editWorkerRecords(isPackage){
			this.isPackage = isPackage;
			this.workerLoading = true;
			this.dlgEditWorkerRecords = true;
		},

		onUpdateComplete(){
			this.loading = true;
			this.getTimesheetWorkTeam();
		},
		validate () {
			this.$refs.form.validate();
		},
		async updateTimesheetWorkTeam(){
			const body = {
				"day_labour_count": parseInt(this.dayLabour.count),
				"package_count": parseInt(this.packageLabour.count)
			}
			try {
				let response = await TimesheetService.updateTimesheetWorkTeam(this.team_id, body);
		 		if(response.data.statusCode === 200){
		 			this.loading = true;
		 			this.snackbar.message = `工作团队${response.data.message}`;
		 			this.snackbar.color = 'success';
		 			setTimeout(()=>{
		 				this.snackbar.model = true;
		 			}, 1000)
		 		}
			} catch(error){
					this.snackbar.message = `错误信息：${error}`;
					this.snackbar.color = 'error';
					setTimeout(()=>{
						this.snackbar.model = true;
					}, 1000)
			}
		},
	},
}
</script>
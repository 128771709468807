<template>
	<v-sheet>
			<v-toolbar
				flat
				dark
				class="fixedToolBar"
				color="primary"
			>
				<slot name="title"></slot>
			</v-toolbar>
			<div style="margin-top:60px; padding-bottom:120px; background-color:#fff;">
				<div class="py-2 px-3">
					<div class="subtitle-1 font-weight-medium">详细记录</div>
				</div>
				<v-divider class="mb-6"></v-divider>
				<template  v-for="(record, index) in timesheets">
					<div class="d-flex align-start pl-6 pr-3" :key="record.id">
						<div class="d-flex flex-column flex-grow-1">
							<div class="d-flex align-center py-2">
								<v-select
									v-model="record.work_type_id"
									dense
									outlined
									hide-details
									:items="workTypes"
									:disabled="!isRecordEditable"
									item-text="name"
									item-value="id"
									label="工作"
									class="mr-3"
								>
								</v-select>

								<v-text-field
									v-model="record.hours"
									dense
									outlined
									type="number"
									:disabled="!isRecordEditable"
									hide-details
									label="工时(小时)"
									required
								></v-text-field>
							</div>
							<div class="d-flex py-2">
								<v-textarea
									v-model="record.notes"
									dense
									outlined
									hide-details
									:disabled="!isRecordEditable"
									label="工作记录">
								</v-textarea>
							</div>
						</div>
						<v-btn
							v-if="isRecordEditable"
							class="ml-2"
							dark
							color="error"
							icon
							@click="popDeleteConfirmation(index)">
							<v-icon>mdi-delete-forever</v-icon>
						</v-btn>
					</div>
					<v-divider class="my-6" :key="index"></v-divider>
				</template>
			</div>
			<v-container class="fixedSaveButton" v-if="isRecordEditable">
				<v-divider class="mb-3"></v-divider>
				<div class="d-flex justify-space-between">
					<v-btn
						color="success"
						elevation="0"
						@click="updateRecords"
						>
						<v-icon left >mdi-content-save</v-icon>
						保存工作记录
					</v-btn>
					<v-btn
						text
						color="primary"
						@click="addRecord"
						>
						<v-icon left>mdi-plus</v-icon>
						添加工作记录
					</v-btn>
				</div>
			</v-container>
			<v-dialog v-model="dlgConfirmation" max-width="320">
				<v-card>
					<v-card-title>确认删除工作记录？</v-card-title>
					<v-card-text>确认删除工作记录吗？ 工作记录删除后将丢失。</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" text @click="dlgConfirmation = false">取消</v-btn>
						<v-btn elevation="0" color="info" @click="removeRecord(deleteIndex)">确认删除</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		<!--- Snackbar --->
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-sheet>
</template>

<script>
import TimesheetService from '../../services/Timesheet';

export default {
	name: 'TeamWorkerRecords',
	data: () => ({
		timesheets: [],
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		dlgConfirmation: false,
		deleteIndex: null
	}),
	props: {
		isPackage: {
			type: Boolean,
			required: true,
			default: false,
		},
		timesheetTeamId: {
			required: true
		},
		workTypes: {
			type: Array,
			required: true
		},
		isRecordEditable: {
			type: Boolean,
			required: true,
			default: true
		}
	},
	watch: {

	},
	computed: {
	},
	created(){
		if(this.isPackage){
			this.getPackageLabourTimesheet();
		} else {
			this.getDayLabourTimehsheet();
		}
	},
	methods: {
		async getDayLabourTimehsheet(){
			try {
				let response = await TimesheetService.getDayLabourTimesheets(this.timesheetTeamId);
				if(response.data.statusCode === 200){
					const timesheets = response.data.data.timesheets;
			
					timesheets.forEach( (record)=>{
						let container = {
							"work_type_id": record.work_type.id,
							"hours": record.hours,
							"notes": record.notes
						}
						this.timesheets.push(container);
					})
				}
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		async getPackageLabourTimesheet(){
			try {
				let response = await TimesheetService.getPackageLabourTimesheets(this.timesheetTeamId);
				if(response.data.statusCode === 200){
					const timesheets = response.data.data.timesheets;
			
					timesheets.forEach( (record)=>{
						let container = {
							"work_type_id": record.work_type.id,
							"hours": record.hours,
							"notes": record.notes
						}
						this.timesheets.push(container);
					})
				}
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}	
		},
		addRecord(){
			const newFields = {
				"work_type_id": 1,
				"hours": 0,
				"notes": ''
			}
			this.timesheets.push(newFields);
		},
		removeRecord(index){
			this.timesheets.splice(index, 1);
			this.dlgConfirmation = false;
			this.updateRecords()
		},
		popDeleteConfirmation(index){
			this.deleteIndex = index;
			this.dlgConfirmation = true;
		},
		async updateDayLabourRecords(){
			let body = {
				"timesheets" : this.timesheets
			}
			try {
				let response = await TimesheetService.updateDayLabourTimesheets(this.timesheetTeamId, body);
				if(response.data.statusCode === 200){
					this.snackbar.message = `工作记录${response.data.message}`;
					this.snackbar.color = 'success';
					this.$emit('on-update-complete');
					this.snackbar.model = true;
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.snackbar.model = true;
			}
		},
		async updatePackageLabourRecords(){
			let body = {
				"timesheets" : this.timesheets
			}
			try {
				let response = await TimesheetService.updatePackageLabourTimesheets(this.timesheetTeamId, body);
				if(response.data.statusCode === 200){
					this.snackbar.message = `工作记录${response.data.message}`;
					this.snackbar.color = 'success';
					this.$emit('on-update-complete');
					this.snackbar.model = true;
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.snackbar.model = true;
			}
		},
		updateRecords(){
			if(this.isPackage){
				this.updatePackageLabourRecords();
			} else {
				this.updateDayLabourRecords();
			}
		}
	}
}
</script>

<style>
	.fixedSaveButton {
		position: fixed;
		bottom: 0px;
		left: 0;
		right: 0;
		background: #fff;
		padding-bottom: 32px;
	}
	.fixedToolBar {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1;
	}
</style>

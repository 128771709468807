<template>
	<v-card>
		<v-card-title>
			 <slot name="title"></slot>
		</v-card-title>
		<v-divider></v-divider>
		<template v-if="workers.length">
			<v-list flat class="pa-0" dense>
				<v-list-item-group v-for="worker in workers" :key="worker.id">
					<v-list-item :disabled="isWorkerDisabled(worker.id)"  class="px-6">
						<v-list-item-content>
							<v-list-item-title>{{worker.name}}</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action>
							<v-btn text color="success" :disabled="isWorkerDisabled(worker.id)" @click.stop="$emit('on-worker-select', worker.id)">
								添加
							</v-btn>
						</v-list-item-action>
					</v-list-item>
					<v-divider></v-divider>
				</v-list-item-group>
			</v-list>
		</template>
		<template v-else>
			<v-alert
				class="ma-3"
				text
				dense
				type="info"
				>
					<div class="body-2">当前团队没有工人</div>
			</v-alert>
		</template>
		<v-card-actions>
			<v-spacer></v-spacer>
			<slot name="actions"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
import WorkTeamService from '../../services/WorkTeam';
import teamList from './teamList.vue';

export default {
  components: { teamList },
	name: 'TeamWorkerList',
	data: () => ({
		workers: [],
	}),
	props: {
		teamId: {
			required: true
		},
		existingWorkerIds: {
			type: Array,
			required: false,
		}
	},
	watch: {

	},
	computed: {
	},
	created(){
		this.getWorkers();
	},
	methods: {
		async getWorkers(){
			try {
				let response = await WorkTeamService.getWorkTeam(this.teamId);
				this.workers = response.data.data.workers;
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		isWorkerDisabled(workerId){
			if(this.existingWorkerIds){
				return this.existingWorkerIds.indexOf(workerId) < 0 ? false : true;
			} else {
				return false;
			}
		},
	}
}
</script>

<template>
	<v-card>
		<v-card-title>
			 <slot name="title"></slot>
		</v-card-title>
		<v-divider></v-divider>
		<v-list flat class="pa-0">
			<v-list-item-group v-for="team in teams" :key="team.id">
				<v-list-item :disabled="isTeamDisabled(team.name)"  class="px-6">
					<v-list-item-content>
						<v-list-item-title>{{team.name}}</v-list-item-title>
						<v-list-item-subtitle>{{team.type | getTypeLabel}}</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn text color="success" :disabled="isTeamDisabled(team.name)" @click.stop="$emit('on-team-select', team)">
							添加
						</v-btn>
					</v-list-item-action>
				</v-list-item>
				<v-divider></v-divider>
			</v-list-item-group>
		</v-list>
		<v-card-actions>
			<template v-if="totalPages > 1">
				<v-btn
					text
					@click="previousPage"
					:disabled="prevPageBtnState"
				>上一页</v-btn>
				<v-btn
					text
					@click="nextPage"
					:disabled="nextPageBtnState"
				>下一页</v-btn>
			</template>
			<v-spacer></v-spacer>
			<slot name="actions"></slot>
		</v-card-actions>
	</v-card>
</template>

<script>
import WorkTeamService from '../../services/WorkTeam';

export default {
	name: 'TeamList',
	data: () => ({
		teams: [],
		itemsPerPage: 10,
		totalPages: 1,
		currentPage: 1,
		status: 0,
	}),
	props: {
		existingTeamNames: {
			type: Array,
			required: false,
		}
	},
	watch: {

	},
	computed: {
		nextPageBtnState(){
			return this.currentPage < this.totalPages ? false : true;
		},
		prevPageBtnState(){
			return this.currentPage > 1 ? false : true;
		}
	},
	created(){
		this.getTeams();
	},
	filters: {
		getTypeLabel(value){
			return value === "team" ? "团队" : "个人";
		}
	},
	methods: {
		async getTeams(){
			this.loading = true
			try {
				let params = {
					"archived": this.status,
					"page": this.currentPage,
					"per_page": this.itemsPerPage,
					"include_projects": 1
				};

				let response = await WorkTeamService.getWorkTeamList(params)
				this.teams = response.data.data.items;
				this.itemsPerPage = response.data.data.max_items_per_page;
				this.totalPages = response.data.data.total_pages;
				this.currentPage = response.data.data.current_page;

				this.loading = false;
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		isTeamDisabled(teamName){
			if(this.existingTeamNames){
				return this.existingTeamNames.indexOf(teamName) < 0 ? false : true;
			} else {
				return false;
			}
		},
		nextPage(){
			if(this.currentPage < this.totalPages){
				this.currentPage++;
				this.getTeams();
			}
		},
		previousPage(){
			if(this.currentPage > 1){
				this.currentPage--;
				this.getTeams();
			}
		}
	}
}
</script>

import Api from '@/services/Api';

export default {
	getWorkerList(page, itemsPerPage, isArchived){
		return Api().get(`/worker?page=${page}&per_page=${itemsPerPage}&archived=${isArchived}`);
	},
	getWorker(workerId){
		return Api().get(`/worker/${workerId}`);
	},
	careateWorker(body){
		return Api().post(`/worker`, JSON.stringify(body));
	},
	updateWorker(workerId, body){
		return Api().put(`/worker/${workerId}`, JSON.stringify(body));
	},
	
}